import axios from 'axios'

const request = axios.create({
    
  baseURL:   'https://api.laravel.vxp8504.uta.cloud/',
  timeout: 20000,
  // withCredentials: true,

});

export default request